.video-frame:hover{
    cursor: pointer;    
}

.video-play{
    width: 100%;
    height: 150px;
    background-image: url("../../images/image_video@2x.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.video-play-mobile{
    width: 100%;
    height: 75px;
    background-image: url("../../images/image_video@2x.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.video-play:hover{
    opacity:0.8;
    cursor: pointer;
    transform: scale(1.1);
}

.video-bg{
    width: 100%;
    height: 1000px;
    background-image: url("../../images/video-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.video-bg-mobile{
    width: 100%;
    height: 500px;
    background-image: url("../../images/video-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


.video-row{
    justify-content: center;
    display: flex;
    height: 1000px;
    align-items: center;
}


.video-row-mobile{
    justify-content: center;
    display: flex;
    height: 400px;
    align-items: center;
}