:root {
  --primary: #6600FF;
  --dark-blue: #1B1464;
  --grey-primary: #707070;
}

body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

html, body {
  position:relative;
  overflow-x:hidden;
}

.ios-icon {
  background-image: url("./images/image_apple_store@2x.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 45%;
  height: 4em;
  cursor: pointer;
  margin-left: 5%;
}

.ios-icon:hover {
  transform: scale(1.1);
}

.android-icon {
  background-image: url("./images/image_google_store@2x.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 45%;
  height: 4em;
  margin-left:4%;
  cursor: pointer;

}

.android-icon:hover {
  transform: scale(1.1);
}