@font-face {
    font-family: 'icomoon';
    src:  url('./fonts/icomoon.eot?9tkvr2');
    src:  url('./fonts/icomoon.eot?9tkvr2#iefix') format('embedded-opentype'),
      url('./fonts/icomoon.ttf?9tkvr2') format('truetype'),
      url('./fonts/icomoon.woff?9tkvr2') format('woff'),
      url('./fonts/icomoon.svg?9tkvr2#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-font_wedance-66:before {
    content: "\61";
  }
  .icon-font_wedance-67:before {
    content: "\62";
  }
  .icon-font_wedance-68:before {
    content: "\63";
  }
  .icon-font_wedance-71:before {
    content: "\64";
  }
  .icon-font_wedance-69:before {
    content: "\65";
  }
  .icon-font_wedance-70:before {
    content: "\66";
  }
  .icon-font_wedance-59:before {
    content: "\6c";
  }
  .icon-font_wedance-50:before {
    content: "\48";
  }
  .icon-font_wedance-31:before {
    content: "\44";
  }
  .icon-font_wedance-45:before {
    content: "\39";
  }
  .icon-font_wedance-24:before {
    content: "\78";
  }
  .icon-font_wedance-14:before {
    content: "\6a";
  }
  .icon-font_wedance-13:before {
    content: "\49";
  }
  .icon-font_wedance-08:before {
    content: "\6f";
  }
  .icon-font_wedance-07:before {
    content: "\70";
  }
  .icon-font_wedance-03:before {
    content: "\74";
  }
  .icon-font_wedance-02:before {
    content: "\76";
  }